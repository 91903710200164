import { Container } from 'unstated-typescript'
import { produce } from 'immer'
import axios, { AxiosResponse } from 'axios'
import { update } from '../clients/login'
import { getFromCache } from '../utils/cache'
import { getGeoInfo, GeoInfo } from '../utils/currentCountry'
import { EventEmitter } from '../utils/events'
import { FCM } from '@capacitor-community/fcm'
import {FirebaseAnalytics} from '@capacitor-community/firebase-analytics'
import { PushNotifications, PushNotification, PushNotificationToken, PushNotificationActionPerformed } from '@capacitor/push-notifications'
import { pushNotifOpen, setUserId } from '../pages/firebase/firebaseTags'
import { Device } from '@capacitor/device';
import { App } from '@capacitor/app';
import { supportedCountries } from '../utils/countriesTexts'
import { getLocalStorageObject } from '../utils/localstorageData'

let loadUnique = true

declare global {
  interface Window {
    firebase: any
  }
}
interface UserModel {
  active: boolean
  email: string
  id: string
  name: string
  username: string
  phone: string
  alternativePhone: any[]
  contact: string
  termsandconditions: boolean
  formLock: boolean
  showModalGuruPro: boolean
}
interface UserState {
  isAuthenticated: boolean
  user: UserModel | any
  access_token: any
  interceptor: any
  isRegistration: boolean
}

const storageAccessTokenName = '@access_token'
const storageUserName = '@user'

export default class User extends Container<UserState> {
  state: UserState = {
    isAuthenticated: false,
    user: null,
    access_token: null,
    interceptor: null,
    isRegistration: false,
  }

  constructor() {
    super()
    const user = getFromCache<any>(storageUserName)
    const accessToken = getFromCache<any>(storageAccessTokenName)
    try {
      if (accessToken) {
        this.createInterceptor(accessToken).then((response) => {
          this.requestPermission()
          this.setState(
            produce(this.state, draft => {
              draft.user = user
              draft.interceptor = response
              draft.access_token = accessToken
              draft.isAuthenticated = true
            }),
          )
          setTimeout(() => {
            setUserId(user && user.id)
          }, 3000)

        })
      }

    } catch (error) {
      console.error("Error", error)
    }
  }

  async createInterceptor(accessToken: any) {
    try {
      const deviceInfo = await Device.getInfo()
      const version  = "4.21.0"
      const appInfoCapacitor = await App.getInfo().catch(err => null)

      const metadata = {
        appVersion: appInfoCapacitor?.version || "",
        deviceModel: deviceInfo.model,
        device: deviceInfo.platform,
        codebaseVersion: version
      }
      const countryData: GeoInfo = await this.getCountryData()

      return axios.interceptors.request.use(
        async (config: any) => {
          
          const currentOffice = localStorage.getItem('currentOffice')

          const officeParsed = currentOffice && currentOffice !== '' && currentOffice !== 'undefined' ? JSON.parse(currentOffice) : null
         
          const token = localStorage.getItem('@access_token')
          config.headers.Authorization = `Bearer ${token && token !== '' && token !== 'undefined' ? JSON.parse(token) : accessToken}`
          config.headers.device = deviceInfo.platform
          config.headers.metadata = JSON.stringify(metadata)
          config.headers.officeId = officeParsed?._id || null
          config.headers.comuneId = officeParsed?.comuneId || null
          config.headers.bussinesTypeId = officeParsed?.bussinesTypeId || null
          config.headers.countryCode = countryData?.countryCode ?? 'CL'
          return config
        },
        function (error) {
          return Promise.reject(error)
        },
      )
    } catch (error) {
      throw (error)
    }
  }

  async getCountryData() {
    let country: any = localStorage.getItem('countryName')

    if (!country) {
      try {
        const countryData = await getGeoInfo()

        if (!supportedCountries.includes(countryData.countryCode)) {
          const countrySelect = localStorage.getItem('countrySelect')
          const currency = countrySelect && JSON.parse(countrySelect)
          localStorage.setItem('countryName', JSON.stringify(currency))
          country = currency
        } else {
          country = await getGeoInfo()
        }

      } catch (error) {
        console.error("Error", error)
      }
    } else {
      country = JSON.parse(country)
    }
    return country
  }

  async requestPermission() {
    const info = await Device.getInfo()
    try {
      if (PushNotifications && PushNotifications.register && info.platform !== 'web') {
        PushNotifications.register().then(() => {
          if (info.platform === 'ios') {
            FCM
              .getToken()
              .then(r => {
                this.registerNotification(r.token, `native - ${info.platform}`)
              })
              .catch(err => console.error(err))
          }
        })
        PushNotifications.addListener('registration', (token: PushNotificationToken) => {
          if (info.platform === 'android') {
            this.registerNotification(token.value, `native - ${info.platform}`)
          }
        })
        PushNotifications.addListener('pushNotificationReceived', (notification: PushNotification) => {
          this.pushUpdate(notification.data && notification.data.pushId)
        })

        PushNotifications.addListener('pushNotificationActionPerformed', async (action: PushNotificationActionPerformed) => {
          // pushNotifOpen(action?.notification?.data?.deeplink)

          let onNotificationReceived = true
          const intervalDeeplink = setInterval(() => {
            const isLoadHome = localStorage.getItem('loadComplete')
            const isHomeLoadComplete = isLoadHome ? JSON.parse(isLoadHome) : false
            if(isHomeLoadComplete && onNotificationReceived && window.location.href !== action?.notification?.data?.deeplink) {
              EventEmitter.dispatch('NotificationOpened', action?.notification?.data?.deeplink)
              EventEmitter.subscribe('DeeplinkExecuted', async (value:boolean) => {
                if (value) {
                  clearInterval(intervalDeeplink)
                  onNotificationReceived = false
                } 
              })
            } 
          }, 4000)
          this.pushUpdate(action?.notification?.data?.pushId)
        })
      } else if (window.firebase && info.platform === 'web' && info.manufacturer !== 'Apple Computer, Inc.') {
        const messaging = window.firebase.messaging()
          
        if (loadUnique) {
          messaging.usePublicVapidKey(`${process.env.REACT_APP_FIREBASE_WEB_PUSH_API_KEY}`)

          FirebaseAnalytics.enable()
          loadUnique = false
        }
        messaging
          .getToken()
          .then((currentToken: any) => {
            if (currentToken) {
              this.registerNotification(currentToken, 'webpush')
            } else {
              Notification.requestPermission().then(permission => {
                if (permission === 'granted') {
                  messaging.getToken().then((currentToken: any) => {
                    if (currentToken) {
                      this.registerNotification(currentToken, 'webpush')
                    }
                  })
                }
              })
            }
          })
          .catch((err: any) => {
            console.error('An error occurred while retrieving token. ', err)
          })
        messaging.onMessage((payload: any) => {})
      }
    } catch (e) {
      console.error(e)
    }
  }

  registerNotification = async (token: string, platform: string) => {
    if (this.state.isRegistration === false) {
      try {
        await this.setState({
          isRegistration: true,
        })
        const result = await axios.post(`${process.env.REACT_APP_BFF_NODE}/v1/users/notification`, {
          token,
          platform,
        })
        await this.setState({
          isRegistration: false,
        })
        return result.data
      } catch (error) {
        console.error('Error: =>>>', error)
      }
    }
  }

  pushUpdate = async (pushId: any) => {
    try {
      const result = await axios.put(`${process.env.REACT_APP_BFF_NODE}/v1/push/${pushId}`, {
        opened: true,
      })
      return result.data
    } catch (error) {
      console.error('Error: =>>>>>>>', error)
      throw error
    }
  }

  getUser(): UserModel {
    return this.state.user ? this.state.user : getFromCache<any>(storageUserName)
  }

  async saveUser(form: any) {
    try {
      await update(form)
      const user = getFromCache<any>(storageUserName)
      user.email = form.email
      user.phone = form.phone
      if (form.alternativePhones) user.alternativePhones = form.alternativePhones
      delete user.altPhone
      this.setState({
        user,
      })
      localStorage.setItem(storageUserName, JSON.stringify(user))
    } catch (err) {
      throw err
    }
  }

  async editCacheUser(form: any) {
    try {
      const user = getFromCache<any>(storageUserName)
      user.email = form.email
      user.phone = form.phone
      user.alternativePhones = form.altPhone === "" ? [] : [form.altPhone]
      this.setState({
        user,
      })
      localStorage.setItem(storageUserName, JSON.stringify(user))
    } catch (err) {
      throw err
    }
  }

  isAuthenticated() {
    return this.state.isAuthenticated
  }

  setUserPhone = async (phone: any) => {
    await this.setState(
      produce(this.state, draft => {
        const user = this.state.user || {
          phone: null,
        }
        user.phone = phone
        draft.user = user
      }),
    )
  }

  authenticateWith = async (response: any) => {
    const user = response.user as UserModel
    const interceptor = await this.createInterceptor(response.access_token)
    await this.requestPermission()
    localStorage.setItem(storageAccessTokenName, JSON.stringify(response.access_token))
    localStorage.setItem(storageUserName, JSON.stringify(user))

    setTimeout(() => {
      this.setState(
        produce(this.state, draft => {
          draft.user = user
          draft.isAuthenticated = true
          draft.access_token = response.access_token
          draft.interceptor = interceptor
        }),
      )
    }, 2500)
  }

  async signOut() {
    axios.interceptors.request.eject(this.state.interceptor)
    await this.setState(
      produce(this.state, draft => {
        draft.user = null
        draft.isAuthenticated = false
        draft.access_token = null
        draft.interceptor = null
      }),
    )
    localStorage.removeItem(storageAccessTokenName)
    localStorage.removeItem(storageUserName)
    localStorage.removeItem('currentOffice')
    localStorage.clear()
  }

  appVersion = async (info: any) => {
    try {
      const result = await axios.post(`${process.env.REACT_APP_BFF_NODE}/v1/app/version`, {
        info,
      })
      return result.data
    } catch (error) {
      console.error('Error: =>>>>>>>', error)
      return null
    }
  }

  appCurrentVersion = async () => {
    try {
      const result = await axios.get(`${process.env.REACT_APP_BFF_PUBLIC}/v1/app/version`)
      return result.data
    } catch (error) {
      console.error('Error: =>>>>>>>', error)
      return null;
    }
  }

  getUserRecovery = async (username: any) => {
    try {
      const result = await axios.get(`${process.env.REACT_APP_BFF_NODE}/v1/users/recovery/${username}`)
      return result.data
    } catch (error) {
      console.error('Error: =>>>>>>>', error)
      throw error
    }
  }

  updateUserValues = async (data: any) => {
    try {
      const result = await axios.put(`${process.env.REACT_APP_BFF_NODE}/v1/users/update`, data)
      return result.data
    } catch (error) {
      console.error('Error: =>>>>>>>', error)
      throw error
    }
  }

  getUserMe = async () => {
    try {
      const result = await axios.get(`${process.env.REACT_APP_BFF_NODE}/v1/users/me`)
      return result.data
    } catch (error) {
      console.error('Error: =>>>>>>>', error)
      return null
    }
  }

  usersUpdate = async () => {
    try {
      const result = await axios.put(`${process.env.REACT_APP_BFF_NODE}/v1/users/update`, {})
      return result.data
    } catch (error) {
      console.error('Error: =>>>>>>>', error)
      return null
    }
  }

  userProfileOptional = async (id: string) => {
    try {
      const result = await axios.get(`${process.env.REACT_APP_BFF_NODE}/v1/typeOfBussiness/${id}`)
      return result.data
    } catch (error) {
      console.error('Error: =>>>>>>>', error)
    }
  }

  loginShowCasePhoto = async (country: string) => {
    try {
      const result = await axios.get(`${process.env.REACT_APP_BFF_NODE}/v1/loginShowCases`, {
        headers: {
          countryCode: country
        }
      })
      return result.data
    } catch (error) {
      console.error('Error: =>>>>>>>', error)
    }
  }

  refreshAccessToken = async (idUser: string) => {
    try {
      const result = await axios.get(`${process.env.REACT_APP_BFF_NODE}/v1/users/refreshAccessToken/${idUser}`)
      return result.data
    } catch (error) {
      console.error('Error: =>>>>>>>', error)
    }
  }

  onboarding = async () => {
    try {
      const result = await axios.get<any>(`${process.env.REACT_APP_BFF_PUBLIC}/v1/onboarding`);
      return result.data
    } catch (error) {
      console.error('Error: =>>>>>>>', error)
    }
  }

  userAccountDeletion = async () => {
    try {
      const result = await axios.post(`${process.env.REACT_APP_BFF_NODE}/v1/users/deletionRequest`, {})
      return result
    } catch (error) {
      console.error('Error: =>>>>>>>', error)
      return null
    }
  }

  authUser = async () => {
    const authData = JSON.parse(localStorage.getItem("registerSuccesfull"));
    if (!authData) return;
    
    await this.authenticateWith(authData)
    await this.editCacheUser(authData.user)
  }

  getSalesmanInfo = async (providerIds: string[]) => {
    try {
      const result = await axios.get(`${process.env.REACT_APP_BFF_NODE}/v1/salesmen?providerIds=${providerIds.join(",")}`)
      return result.data

    } catch(error){
      console.error('Error: =>>>>>>>', error)
    }
  }

}
