import React, { Fragment } from 'react'
import { IonContent, IonText, IonToast, IonAlert, IonSpinner, IonModal, IonTextarea, IonIcon, IonSelect, IonSelectOption } from '@ionic/react'
import track from 'react-tracking'
import User from '../../models/User'
import Settings from '../../models/Settings'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { ProductModel } from '../../models/ProductModel'
import { CartModel, CartProduct } from '../../models/CartModel'
import { ProviderModel, Provider } from '../../models/ProviderModel'
import Office from '../../models/Office'
import SurveyModel from '../../models/Survey'
import { groupBy } from '../../utils/arrays'
import {
  OrderProduct,
  Order,
  postOrder,
  find,
  getEnrolado,
  oneClick as modelOneClick,
  paymentmethods,
  getEnrolamiento,
  enrolamiento,
  addCard,
  paymentOrder,
  guethShippingGuruProvider,
} from '../../clients/order'

import ButtonComponent from '../../components/basic-button/ButtonComponent'
import TextInput from '../../components/input-form/TextInput'
import ToolBar from '../../components/tool-bar/ToolBar'
import AlertModal from '../../components/modals/AlertModal'
import GuruPro from '../guru-pro/GuruPro'
import { validateEmail } from '../../utils/basetypes'
import {
  eventForCheckout,
  fbPurchaseEvent,
  errorCheckoutEvent,
  productPurchasedEvent,
  dispatchProgramClick,
  dispatchModifyDayClick,
  dispatchConfirmDayClick,
  dispatchConfirmModifyDayClick,
  eventConfirmOrderClick,
  returnToCartClickEvent,
  registerBasicUserInfoEvent,
} from '../firebase/firebaseTags'
import OrderConfirmedModal from '../../components/order-confirmed-modal/OderConfirmedModal'
import PaymentModal from '../payment/PymentModal'
import { first } from 'lodash'
import { formatCurrency, weekDay, month, idCentralMaorista } from '../../utils/intl'
import { getDeliveryDate, getDayOfMoment } from '../../utils/cutDay'

import { Browser } from '@capacitor/browser'

import { Device } from '@capacitor/device'

import { displayByCountry, currentApp } from '../../utils/currentCountry'
import { getSurveyByKey } from '../../utils/survey'
import { ordersPaidEvents } from '../../utils/firebaseEvents'
import Arrow from './../../assets/icons/nav_right.svg'
import locationIcon from '../../assets/icons/icon_location_blue.svg'

import { getLocalStorageObject, getLocalUserData } from '../../utils/localstorageData'
import {
  commentPlaceholderByCountry,
  countryCl,
  countryMx,
  countryPe,
  getCountryCode,
} from '../../utils/countriesTexts'
import { isPhoneValidByCountry, phoneLengthByCountry, phonePlaceholderByCountry } from '../../utils/countriesTexts'
import ShippingGuru from './ShippingGuru'

import moment from 'moment'
import './OrderConfirmation.scss'

interface IPathParams {
  id: string
}

type IProps = RouteComponentProps<IPathParams> & {
  readonly user: User
  readonly settings: Settings
  readonly productModel: ProductModel
  readonly cartModel: CartModel
  readonly providerModel: ProviderModel
  readonly offices: Office
  surveyModel?: SurveyModel
  tracking?: any
  singleProviderId?: string
  form?: any
  metodoPagoUser: any
  productsGroupedMethod: any
  paymentMethod: string
  isOpenModal: boolean
  closeModalOrder: any
  totalOrder?: number
  cuponsActiveCard?: any
  totalCart: number
  vendorsShippingCost: any
  totalShippingCost: any
  appliedShippingCost: any
}

interface State {
  selectedAddressIndex: number
  showToast: boolean
  success: boolean
  guruProModal: Boolean
  onSending: boolean
  form: { phone: string; email: string; comment: string }
  offices: any[]
  officeState: any
  alertMinimumOrder: { show: false } | { show: true; providers?: Provider[] }
  provider: any
  spinner: boolean
  userInfo: any
  selectCard: string
  btnDisabled: boolean
  couponsActive: any[]
  oneClick: any[]
  bancoEstado: any[]
  paymentMethods: any[]
  valueSelected: string
  statusOrder: any
  selectAccount: string
  isWeb: boolean
  country: any
  showModadalPaymentMethod: boolean
  paymentName: string
  totalDiscountPromotion: number
  promotion: any
  getEnrollBech: boolean
  getEnrollOneClick: boolean
  paymentSelected: any
  comment: any
  deviceInfo: any
  loadingProcessingOrder: boolean
  nameProcessingOrder: string
  showModalExceedPayment: boolean
  showShippingGuru: boolean
  providerShippingGuru: any
  arrProviderSelectedDate: any
  nameContainerShowShippingGuru: string
  vendorsShippingCost?: any
  totalShippingCost?: number
  placeholderFulfillment: boolean
  providerSalesmen: any
  selectedSalesmen: any,
  isValidSalesmen: boolean
}

type SubTotals = { [key: string]: { subTotal: number; minimum?: number; paymentMethods?: any; isIncluded: boolean } }
const BECH = 'bancoEstado'
const WEBPAY = 'webPay'
const ONECLICK = 'oneClick'
const IZIPAY = 'iziPay'
const ETPAY = 'etPay'

class OrderConfirmation extends React.PureComponent<IProps, State> {
  state: State = {
    selectedAddressIndex: 0,
    showToast: false,
    success: false,
    guruProModal: false,
    onSending: false,
    form: {
      phone: '',
      email: '',
      comment: '',
    },
    offices: [],
    officeState: {},
    alertMinimumOrder: { show: false },
    provider: '',
    spinner: false,
    userInfo: '',
    selectCard: '',
    btnDisabled: false,
    couponsActive: this.props.cuponsActiveCard,
    oneClick: [],
    bancoEstado: [],
    paymentMethods: [],
    valueSelected: '',
    statusOrder: {},
    selectAccount: '',
    isWeb: false,
    country: {},
    showModadalPaymentMethod: false,
    paymentName: 'Elegir',
    totalDiscountPromotion: 0,
    promotion: '',
    getEnrollBech: false,
    getEnrollOneClick: false,
    paymentSelected: '',
    comment: '',
    deviceInfo: '',
    loadingProcessingOrder: false,
    nameProcessingOrder: '',
    showModalExceedPayment: false,
    showShippingGuru: false,
    providerShippingGuru: [],
    arrProviderSelectedDate: [],
    nameContainerShowShippingGuru: 'Elige el día',
    placeholderFulfillment: false,
    providerSalesmen: [],
    selectedSalesmen: [],
    isValidSalesmen: true
  }

  componentDidMount = async () => {
    const currentCarts = JSON.parse(localStorage.getItem('@cart')!) || null
    const preProducts = JSON.parse(localStorage.getItem('preProducts')!) || null

    if (currentCarts) {
      const { products } = currentCarts
      const checkColiseo = products.some((product: any) => product.provider.trim() === "Gurú Despacho")
      if (checkColiseo === true) {
        this.setState({ placeholderFulfillment: true })
      }
    }

    if (currentCarts && preProducts) {
      const addedProducts: string[] = []
      currentCarts.products.forEach((product: any) => {
        let productFound: boolean = false
        preProducts.forEach((preProduct: any) => {
          if (product.name === preProduct.name) {
            productFound = true
          }
        })
        if (productFound === false) addedProducts.push(product.name)
      })

      // if (addedProducts.length > 0) {
      //   updateCartClickEvent(addedProducts)
      // // } else {
      // //   // continueUnchangedClickEvent()
      // // }
    }

    const { offices, user, productsGroupedMethod } = this.props

    const infoApp = await Device.getInfo()

    if (infoApp.platform === 'web') this.setState({ isWeb: true })

    this.setState({
      deviceInfo: infoApp,
    })

    const providers: any = await guethShippingGuruProvider(Object.keys(productsGroupedMethod).join())
    this.setState({ providerShippingGuru: providers })
    
    const userInfo: any = (await this.props.user.getUserMe()) || null
    this.setState({
      userInfo,
    })
    this.setState({selectedSalesmen: userInfo.selectedSalesmen})
    const metodoPago = this.assignPaymentObject()
    localStorage.setItem('paymentMethods', JSON.stringify(metodoPago))
    
    const totalPre = localStorage.getItem('totalPre')
    const totalPrePayment = totalPre ? JSON.parse(totalPre) : 0

    this.oneClickData()
    this.enrollData()

    const vendorIds = Object.keys(metodoPago.productsGroupedMethod).join()

    const paymentMethods = totalPrePayment > 0 && (await paymentmethods(totalPrePayment, true, vendorIds))

    this.setState({ paymentMethods })
    const userR = await user.getUser()

    if (userR?.email.split('almacenguru.cl').length > 1) {
      userR.email = ''
    }

    this.setState({
      form: this.initUserForm(userR),
    })
    const officesList = await offices.get()
    const currentOffice = localStorage.getItem('currentOffice')
    const office = currentOffice ? JSON.parse(currentOffice) : first(officesList)
    const selectedAddressIndex = officesList.findIndex((element) => element._id === office._id)
    this.setState({
      offices: officesList,
      officeState: office,
      selectedAddressIndex: selectedAddressIndex >= 0 ? selectedAddressIndex : 0,
    })

    const countryName = localStorage.getItem('countryName')
    const country = countryName ? JSON.parse(countryName) : ''
    this.setState({ country: currentApp().find((route: any) => country.countryCode === route.country) })
    const providerData = await this.props.user.getSalesmanInfo(Object.keys(metodoPago.productsGroupedMethod))
    this.setState({providerSalesmen : providerData})

  }

  getShippingCostProvider = (vendorId: string) => {
    const { vendorsShippingCost } = this.props

    if (vendorsShippingCost) {
      return vendorsShippingCost[vendorId]
    }
    return null
  }

  getAppliedCostProvider = (vendorId: string) => {
    const { appliedShippingCost } = this.props

    if (appliedShippingCost) {
      return appliedShippingCost[vendorId]
    }
    return {}
  }

  onChangeInput = (field: string, value: any) => {
    const { form } = this.state

    this.setState({
      form: {
        ...form,
        [field]: value,
      },
    })
  }

  initUserForm = (userData: any) => {
    const phone = userData.phone ? userData.phone.trim() : ''
    const email = userData.email ? userData.email.trim() : ''

    return {
      phone,
      email,
      comment: '',
    }
  }

  shippingGuruIsEnabled = () => {
    return this.state.providerShippingGuru.filter((provider: any) => provider.nextDays.length).length
  }

  private setShowToast = (showToast: boolean, callback?: any) => this.setState({ showToast }, callback)

  private showAlert = (providers: Provider[]) => this.setState({ alertMinimumOrder: { show: true, providers } })

  private hideAlert = () => this.setState({ alertMinimumOrder: { show: false } }, () => this.props.closeModalOrder())

  private getAlertText = (prov?: Provider[]) => {
    if (prov && prov.length) {
      return `Un proovedor no llega al mínimo de compra, permanecerá en el carro hasta completarse.
      <br>      ¿Quieres continuar?`
    }
    return ''
  }

  private getTotalPrice = (products: CartProduct[]): number =>
    products
      .map((item: any) => {
        return item.infoPrice
      })
      .reduce<number>((sum, { totalPrice }) => sum + totalPrice, 0)

  private sendOrder = async (isPayment: any, isNotPayment: any, isCredit: any, discountVerified: any) => {
    const {
      productsGroupedMethod,
      cartModel,
      user: userModel,
      providerModel,
      singleProviderId,
      paymentMethod,
    } = this.props

    const { offices, selectedAddressIndex, form, arrProviderSelectedDate, selectedSalesmen } = this.state
    const metodoPago: any = this.assignPaymentObject()
    localStorage.setItem('paymentMethods', JSON.stringify(metodoPago))

    const user = userModel.getUser()
    if (!user) {
      this.setState({
        onSending: false,
        spinner: false,
      })
      return
    }

    let pay: any = localStorage.getItem('paymentMethods')
    if (pay) {
      pay = JSON.parse(pay)
    }

    const discountsInCart = cartModel.getDiscounts()
    let productsGrouped = productsGroupedMethod

    const discountsGrouped = groupBy(discountsInCart, (p: any) => p?.providerId)
    let total = 0
    const providerIdsToKeep = new Set<string>()
    try {
      await this.props.user.saveUser(this.state.form)
    } catch (err) {
      console.error(err)
    }

    for (const providerId in productsGrouped) {
      const shippingCostProvider = this.getShippingCostProvider(providerId)
      const shippingCostApplied = this.getAppliedCostProvider(providerId)
      let provider: any = providerModel.getById(providerId)
      let products = productsGrouped[providerId]
      const subTotal = this.getTotalPrice(products)
      metodoPago.metodoPago.filter((r: any) => {
        if (r.providerId === provider.id) return (provider = Object.assign(provider, r))

        return null
      })

      let isPaymentForm = provider?.type === 'pre-pago'

      if (
        (provider.paymentMethods && isPaymentForm === true) ||
        (singleProviderId && providerId !== singleProviderId) ||
        (provider.minimun && subTotal < provider.minimun)
      ) {
        providerIdsToKeep.add(providerId)
        continue
      }

      const orderProducts = products.map(({ infoPrice, price, quantity, sku, units, packageType }: any) => {
        const product: OrderProduct = { infoPrice, price, quantity, sku, units, packageType }
        return product
      })

      let discounts = this.localStorageCart().discounts
      discounts = discounts.filter(
        (discount: any) =>
          (discount.providerId && discount.providerId === providerId) || discount.discountType === 'pay-promo',
      )

      const providerDispatchtimes = arrProviderSelectedDate.find((privider: any) => providerId === privider._id)
      let aux = this.state.selectedSalesmen.find((elem) => elem.providerId === providerId)
      let providerSalesmen = this.state.providerSalesmen.find((elem) => elem.providerId === providerId)

      let salesman = providerSalesmen.salesmen.find((elem) => elem._id === aux.salesmanId )

      const order: Order = Object.assign(
        {
          officeId: offices[selectedAddressIndex]._id,
          providerId,
          products: orderProducts,
          discounts,
          paymentMethodsType: provider.type,
          paymentMethods: provider.paymentMethods,
          cartId: this.cartId(),
          isCredit: paymentMethod === 'credito',
          dispatchtimes: providerDispatchtimes,
          shippingCostProvider,
          shippingCostApplied,
          salesman: {
            salesmanId: salesman._id,
            salesmanIdentifier: salesman.identifier
          }
        },
        {
          contact: form,
        },
      )

      total += subTotal
      const result = await postOrder(order)
      const userOrders = await find(0, 1)
      const userOrdersQuantity = userOrders.length

      if (result.status === 200) {
        const { productsGroupedMethod, cuponsActiveCard, paymentMethod } = this.props

        const {
          form: { comment },
        } = this.state

        const orderId = result.data._id

        eventForCheckout(
          'purchase',
          subTotal,
          productsGroupedMethod,
          cuponsActiveCard,
          paymentMethod,
          comment,
          providerId,
        )

        if (!userOrdersQuantity)
          eventForCheckout(
            'first_order_placed',
            subTotal,
            productsGroupedMethod,
            cuponsActiveCard,
            paymentMethod,
            comment,
            providerId,
          )

        productPurchasedEvent(productsGroupedMethod, orderId, providerId)
        fbPurchaseEvent(subTotal)
      } else {
        errorCheckoutEvent('order_placed_error', result.status)
        if (!userOrdersQuantity) errorCheckoutEvent('first_order_placed_error', result.status)
      }
    }

    if (isPayment.length && !isNotPayment.length) {
      this.showAlert(Array.from(providerIdsToKeep).map((providerId) => providerModel.getById(providerId)))
      this.setState({
        spinner: false,
      })
      this.props.history.replace('/payment', { singleProviderId, form, metodoPago, productsGroupedMethod })
    } else if (!isPayment.length && (isNotPayment.length || isCredit.length)) {
      this.setState({
        success: true,
        spinner: false,
        onSending: false,
      })
    } else if (isPayment.length && isNotPayment.length) {
      this.setState({
        spinner: false,
      })
      this.props.history.replace('/payment', { singleProviderId, form, metodoPago, productsGroupedMethod })
    }
  }

  goToDeliveries = () => {
    this.props?.history?.push('/deliveries')
  }

  goToOrders = () => {
    this.props?.history?.push('/orders')
  }

  goToGuruPro = () => {
    this.props?.history?.push('/guru-pro')
  }

  goBack = () => {
    const userID = getLocalUserData().id
    returnToCartClickEvent(userID)

    this.props.history.go(0)
    localStorage.setItem('@goBack', JSON.stringify(true))
  }

  redirectToSurvey = (surveyKey: string) => {
    const { history } = this.props
    history.push('/survey-nps', {
      targetType: 'order-transaction',
      targetId: '',
      key: surveyKey,
    })
  }

  goToSurvey = async () => {
    const { surveyModel } = this.props
    const surveyData = getSurveyByKey('satisfaction-survey')

    const answerData = await surveyModel?.additionalInfoSurvey(surveyData.surveyId)
    if (answerData?.shouldShow) {
      this.redirectToSurvey(surveyData.value)
    } else {
      this.goToOrders()
    }
  }

  onCloseSuccessModal = () => {
    const { userInfo } = this.state
    const surveyData = getSurveyByKey('satisfaction-survey')

    if (userInfo.porcentajeDeAvance < 100 && displayByCountry() && process.env.REACT_APP_GURU_PRO === 'true') {
      this.setState({ guruProModal: true })
    } else {
      this.setState({ guruProModal: false })
      if (surveyData) {
        this.goToSurvey()
      } else {
        this.goToOrders()
      }
    }
  }

  assignPaymentObject = () => {
    const { metodoPagoUser, paymentMethod, productsGroupedMethod } = this.props
    return {
      metodoPago: metodoPagoUser,
      paymentMethod: paymentMethod,
      productsGroupedMethod: productsGroupedMethod,
    }
  }

  discountVerifyByProvider = (providerId: any, discounts: any) => {
    let totalToDiscount = 0
    let result = discounts.filter((detail: any) => detail.provider === providerId)
    if (result.length > 0) {
      totalToDiscount = result[0].realDiscount
    }
    return totalToDiscount
  }

  verifyDiscount = () => {
    const { cartModel, productsGroupedMethod } = this.props
    const { couponsActive } = this.state

    const discountsInCart = couponsActive

    const discountsGrouped = groupBy(discountsInCart, (p) => p.providerId)
    const providersArray = Object.keys(productsGroupedMethod).map((providersId: any) => providersId)

    const countryName = localStorage.getItem('countryName')
    const country = countryName ? JSON.parse(countryName) : null

    let subTotals2: any = []
    for (const provider in discountsGrouped) {
      const isProviderFoundInDiscounts = providersArray.includes(provider)
      subTotals2[provider] = {
        subTotal: discountsGrouped[provider][0].realDiscount,
        isIncluded: isProviderFoundInDiscounts,
      }
    }
    let totalDiscounts = 0
    for (const codeProvider in subTotals2) {
      if (subTotals2[codeProvider]['isIncluded']) {
        if (country?.countryCode === countryCl) {
          totalDiscounts = totalDiscounts + parseInt(subTotals2[codeProvider]['subTotal'])
        } else {
          totalDiscounts = totalDiscounts + subTotals2[codeProvider]['subTotal']
        }
      }
    }
    return totalDiscounts
  }

  openWebviewNative = async (websiteUrl: string) => {
    this.setState({ loadingProcessingOrder: false });

    await Browser.open({ url: websiteUrl });

    Browser.addListener('browserFinished', () => {
      this.setState({ spinner: false, onSending: false });
    });

    Browser.addListener('browserPageLoaded', async () => {
      const url = websiteUrl;

      if (url.includes('/webPay/pay')) {
        await Browser.close();
        this.setState({ success: true });
      } else if (url.includes('/webPay/rejection')) {
        await Browser.close();
        this.setState({
          statusOrder: {
            open: true,
            paid: false,
            subHeader: 'El pago de tu pedido no se pudo realizar. Si persiste el problema, comunícate con nosotros',
            header: 'Pago fallido',
            nameBtn: 'Vamos de nuevo',
            onSeding: false,
            spinner: false,
          },
        });
      } else if (url.includes('/oneClick/inscribe')) {
        await Browser.close();
        this.setState({
          statusOrder: {
            open: true,
            paid: false,
            subHeader: 'Tu método de pago ha sido agregado con éxito. Ya puedes realizar tus compras',
            header: '¡Felicidades!',
            nameBtn: 'Ok',
            onSeding: false,
            spinner: false,
          },
        });
        this.oneClickData();
      } else if (url.includes('/oneClick/duplicate')) {
        await Browser.close();
        this.setState({
          statusOrder: {
            open: true,
            paid: false,
            subHeader: 'Tarjeta esta registrada en el sistema',
            header: 'Duplicado',
            nameBtn: 'Ok',
            onSeding: false,
            spinner: false,
          },
        });
      } else if (url.includes('/oneClick/rejected')) {
        await Browser.close();
        this.setState({
          statusOrder: {
            open: true,
            paid: false,
            subHeader: 'El pago de tu pedido no se pudo realizar. Si persiste el problema, comunícate con nosotros',
            header: 'Pago fallido',
            nameBtn: 'Vamos de nuevo',
            onSeding: false,
            spinner: false,
          },
        });
      } else if (url.includes('/fin_flujo1') || url.includes('/fin_flujo2')) {
        getEnrolamiento();
        this.enrollData();
        await Browser.close();
        this.setState({
          statusOrder: {
            open: true,
            paid: false,
            subHeader: 'Tu método de pago ha sido agregado con éxito. Ya puedes realizar tus compras',
            header: '¡Felicidades!',
            onSeding: false,
            spinner: false,
            nameBtn: 'Ok',
          },
        });
      } else if (url.includes('/fin_flujo3')) {
        await Browser.close();
        this.setState({
          statusOrder: {
            open: true,
            paid: false,
            subHeader: 'Volver a intentar',
            header: '',
            onSeding: false,
            spinner: false,
          },
        });
      }
  });
};

  validDevice = (res: any) => {
    const { deviceInfo } = this.state

    if (deviceInfo.platform === 'web') {
      Browser.open({ url: res.data.url, windowName: '_self' })
    } else {
      this.openWebviewNative(res.data.url)
    }
  }

  enrolamiento2 = async () => {
    this.setState({ spinner: true })
    const res = await enrolamiento()
    this.validDevice(res)
  }

  oneClickData = async () => {
    modelOneClick().then((response: any) => {
      this.setState({ getEnrollOneClick: true })
      if (response && response.length > 0) {
        this.setState({ oneClick: response })
      }
    })
  }

  enrollData = async () => {
    getEnrolado().then((enroll: any) => {
      this.setState({ getEnrollBech: true })
      if (enroll && enroll.length > 0) {
        this.setState({ bancoEstado: enroll })
      }
    })
  }

  redirectPay = () => {
    const { selectAccount, valueSelected, selectCard } = this.state
    if (selectAccount === '' && valueSelected === BECH) {
      this.enrolamiento2()
    } else if (selectAccount !== '' && valueSelected === BECH) {
      this.initialiceOrderPayment()
    } else if (selectCard === '' && valueSelected === ONECLICK) {
      this.addCard2()
      this.setState({ loadingProcessingOrder: true, nameProcessingOrder: 'Proceso oneclick' })
    } else if (selectCard !== '' && valueSelected === ONECLICK) {
      this.initialiceOrderPayment()
    }
  }

  clickButtonPay = () => {
    const { valueSelected, isWeb } = this.state

    if ((valueSelected === BECH && !isWeb) || valueSelected === ONECLICK) this.redirectPay()
    if (valueSelected === WEBPAY || valueSelected === IZIPAY || valueSelected === ETPAY) this.initialiceOrderPayment()
  }

  addCard2 = async () => {
    const { deviceInfo } = this.state
    this.setState({ btnDisabled: true })
    const urlRedirect = deviceInfo.platform === 'web' ? `${process.env.REACT_APP_MOBILE}/cart` : `/cart`
    const res = await addCard(urlRedirect)
    this.validDevice(res)
  }

  payOrder = async (orders: any[], providerIdsToKeep: Set<string>, selectedPaymentMethod: string) => {
    const { selectAccount, selectCard } = this.state
    const { singleProviderId, cartModel } = this.props
    const productsInCart = cartModel.state.products.filter((p) => p.providerId === singleProviderId)
    const idProvider: any = productsInCart.find((r: any) => r.providerId === idCentralMaorista()) || ''
    this.setState({ btnDisabled: true })

    if (selectedPaymentMethod === BECH) {
      const res = await paymentOrder({
        orders,
        typePayment: BECH,
        bancoEstadoAccount: selectAccount,
      })
      if (res.data.paid) {
        ordersPaidEvents()
        this.setState({ statusOrder: { open: true, paid: true, subHeader: 'Realizado', header: '' }, success: true })
      } else {
        this.setState({ statusOrder: { open: true, paid: false, subHeader: res.data.descripcion, header: '' } })
        this.setState({ btnDisabled: false, spinner: false, onSending: false })
      }
    } else if (selectedPaymentMethod === ONECLICK) {
      const res = await paymentOrder({
        orders,
        typePayment: ONECLICK,
        oneClickId: selectCard,
      })
      if (res.data.paid) {
        ordersPaidEvents()
        this.setState({
          loadingProcessingOrder: false,
          success: true,
        })
      } else {
        this.setState({
          loadingProcessingOrder: false,
          statusOrder: {
            open: true,
            paid: false,
            subHeader: 'El pago de tu pedido no se pudo realizar. Si persiste el problema, comunícate con nosotros',
            header: 'Pago fallido',
            nameBtn: 'Vamos de nuevo',
          },
        })
        this.setState({ btnDisabled: false })
      }
    } else if (selectedPaymentMethod === IZIPAY) {
      const res = await paymentOrder({ orders, typePayment: IZIPAY })
      this.validDevice(res)
    } else if (selectedPaymentMethod === ETPAY) {
      const res = await paymentOrder({ orders, typePayment: ETPAY })
      this.validDevice(res)
    } else {
      const res = await paymentOrder({ orders, typePayment: WEBPAY })
      this.validDevice(res)
    }
  }

  initialiceOrderPayment = async () => {
    this.setState({ btnDisabled: true })
    const {
      cartModel,
      providerModel,
      user: userModel,
      singleProviderId,
      productsGroupedMethod,
      cuponsActiveCard,
      totalShippingCost,
    } = this.props
    const { userInfo, valueSelected, offices, selectedAddressIndex, form, arrProviderSelectedDate } = this.state

    valueSelected === WEBPAY && this.setState({ loadingProcessingOrder: true, nameProcessingOrder: 'Proceso webpay' })

    const user = userModel.getUser()

    const userOrders = await find(0, 1)
    const userOrdersQuantity = userOrders.length

    if (!user) return

    let pay: any = localStorage.getItem('paymentMethods')
    if (pay) {
      pay = JSON.parse(pay)
    }

    const productsInCart = cartModel.getCart()
    let productsGrouped = groupBy(productsInCart, (p: any) => p?.providerId)

    let productToPay: any = {}
    for (const mPago of pay.metodoPago) {
      const providerId = mPago.providerId

      const produdtGrouped = productsGrouped[providerId]
      productToPay = { ...productToPay, [providerId]: produdtGrouped }
    }

    let total = 0
    let ordersPayment = []
    const providerIdsToKeep = new Set<string>()

    for (const providerId in productsGrouped) {
      if (!singleProviderId || (singleProviderId && singleProviderId === providerId)) {
      } else {
        providerIdsToKeep.add(providerId)
        localStorage.setItem('providerIdsToKeep', providerId)
      }
    }

    const arrProductsGroupedMethod = productsGroupedMethod

    productsGrouped = arrProductsGroupedMethod

    let eventsOrders: any[] = []

    for (const providerId in productsGrouped) {
      if (!singleProviderId || (singleProviderId && singleProviderId === providerId)) {
        let sumOfAllProdutcs = 0
        productsGrouped[providerId].forEach(({ infoPrice }) => {
          sumOfAllProdutcs = sumOfAllProdutcs + infoPrice.totalPrice
        })

        let proveedor: any = providerModel.getById(providerId)

        let minimun_proveedor = 0

        let pay: any = localStorage.getItem('paymentMethods')
        if (pay) {
          pay = JSON.parse(pay)
        }

        pay.metodoPago.filter((r: any) => {
          if (r.providerId === proveedor.id) return (proveedor = Object.assign(proveedor, r))

          return null
        })

        if (proveedor.minimun && proveedor.minimun > 0) {
          minimun_proveedor = proveedor.minimun
        }

        if (sumOfAllProdutcs >= minimun_proveedor) {
          const products = productsGrouped[providerId]
          this.localStorageCart()

          let discounts = this.localStorageCart().discounts
          discounts = discounts.filter(
            (discount: any) =>
              (discount.providerId && discount.providerId === providerId) || discount.discountType === 'pay-promo',
          )

          let totalDiscounts = 0
          if (discounts) {
            for (var i = 0; i <= discounts.length - 1; i++) {
              totalDiscounts = totalDiscounts + parseInt(discounts[i].realDiscount)
            }
          }

          const subTotal = this.getTotalPrice(products)
          const orderProducts = products.map(({ infoPrice, price, quantity, sku, units, packageType }) => {
            let product: OrderProduct = { infoPrice, price, quantity, sku, units, packageType }
            return product
          })

          const providerDispatchtimes = arrProviderSelectedDate.find((privider: any) => proveedor.id === privider._id)

          const shippingCostProvider = this.getShippingCostProvider(providerId)
          const shippingCostApplied = this.getAppliedCostProvider(providerId)

          const order: Order = Object.assign(
            {
              officeId: offices[selectedAddressIndex]._id,
              providerId,
              products: orderProducts,
              discounts,
              paymentForm: 'pre-pago',
              paymentMethod: this.validateBech(this.state.valueSelected),
              cartId: this.cartId(),
              dispatchtimes: providerDispatchtimes,
              shippingCostProvider,
              shippingCostApplied,
            },
            {
              contact: { ...userInfo, ...form },
            },
          )

          total += subTotal

          const orderEventObj = {
            eventName: 'purchase',
            totalValue: subTotal,
            groupedProducts: productsGroupedMethod,
            activeCoupons: cuponsActiveCard,
            paymentMethod: 'pre-pago',
            comments: form.comment,
            currentVendor: providerId,
            userOrdersQuantity,
          }

          eventsOrders.push(orderEventObj)

          const ordersTotal: any = (await postOrder(order)).data
          if (ordersTotal?.type === 'max-payment') {
            this.setState({
              showModalExceedPayment: true,
              spinner: false,
            })
          } else {
            const cartId: string = (await this.props.cartModel.getId()) as string
            ordersTotal.cartId = cartId
            ordersTotal.totalAmount = total - totalDiscounts + totalShippingCost
            ordersPayment.push(ordersTotal)
            total = 0
          }
        }
      } else {
        providerIdsToKeep.add(providerId)
        localStorage.setItem('providerIdsToKeep', providerId)
      }
    }

    localStorage.setItem('eventsOrders', JSON.stringify(eventsOrders))

    const supportedMethods = [ONECLICK, WEBPAY, IZIPAY, BECH, ETPAY]
    if (!this.state.showModalExceedPayment && supportedMethods.includes(valueSelected))
      this.payOrder(ordersPayment, providerIdsToKeep, valueSelected)

    return null
  }

  validateBech = (value: any) => (value === BECH ? 'Banco Estado' : value)

  cartId = () => {
    const cart = localStorage.getItem('@cart')
    return cart ? JSON.parse(cart)._id : ''
  }

  continueSale = async (isPayment: any, isNotPayment: any, isCredit: any, discountVerified: any = 0) => {
    const { paymentMethod } = this.props

    registerBasicUserInfoEvent({
      eventName: 'cart_confirm_order_page_finish_order_button',
      placementName: 'cart_confirm_order',
      payment_method: paymentMethod
    })

    localStorage.removeItem('confirmShoppingCartClickProviderTimeStart')

    this.setState({
      onSending: true,
      spinner: true,
    })

    this.saveConfirmOrderEvent()

    if (paymentMethod === 'pre-pago') {
      this.clickButtonPay()
    } else {
      await this.sendOrder(isPayment, isNotPayment, isCredit, discountVerified)
    }
  }

  saveConfirmOrderEvent = () => {
    try {
      const { productsGroupedMethod } = this.props

      for (const providerId in productsGroupedMethod) {
        const shippingCostProvider = this.getShippingCostProvider(providerId)
        const provider = this.props.providerModel.getById(providerId)
        eventConfirmOrderClick({ vendorName: provider.name, providerId, shippingCostProvider })
      }
    } catch (error) {
      console.log(error)
    }
  }

  isValidOneClick = (namePaymentMethods: string, selectAccount: string) => {
    return namePaymentMethods === ONECLICK && selectAccount === ''
  }
  isValidBancoEstado = (namePaymentMethods: string, info: any, selectAccount: string) => {
    const { isWeb } = this.state
    return namePaymentMethods === BECH && !isWeb && selectAccount === ''
  }

  getButtonPayName = () => {
    const { selectCard, selectAccount, valueSelected, deviceInfo } = this.state
    const { totalCart, totalShippingCost } = this.props
    const toPay = totalCart + totalShippingCost
    if (
      this.isValidOneClick(valueSelected, selectCard) ||
      this.isValidBancoEstado(valueSelected, deviceInfo, selectAccount)
    ) {
      return 'Agregar tarjeta'
    }
    return `Pagar Total ${formatCurrency(toPay)}`
  }

  handleFeedbackAlertEvent = () => {
    this.state.statusOrder.paid
      ? this.goToOrders()
      : this.setState({ statusOrder: { open: false, paid: false }, spinner: false, onSending: false })
  }

  closePaymentModal = () => this.setState({ showModadalPaymentMethod: false })

  selectedPayment = (paymentName?: any) => {
    let selectedPayment = paymentName || this.state.paymentSelected

    if (paymentName === 'addCard') selectedPayment = 'OneClick'

    if (paymentName === 'enrollBech') selectedPayment = 'BancoEstado'

    if (paymentName === 'etPay') selectedPayment = 'Transferencia Bancaria'

    this.setState({ paymentName: selectedPayment, paymentSelected: paymentName })

    this.onChangeSelectOption(paymentName)
  }

  choosePaymentMethod = () => {
    const { getEnrollBech, getEnrollOneClick } = this.state

    if (getEnrollBech && getEnrollOneClick) {
      this.setState({ showModadalPaymentMethod: true })
    }
  }

  onChangeSelectOption = async (event?: any | undefined) => {
    switch (event) {
      case 'webPay':
        this.setState({ selectCard: '', btnDisabled: false, valueSelected: WEBPAY })
        this.selectdCard('', WEBPAY)
        break
      case 'iziPay':
        this.setState({ selectCard: '', btnDisabled: false, valueSelected: IZIPAY })
        this.selectdCard('', IZIPAY)
        break
      case 'addCard':
        this.setState({ selectCard: '', btnDisabled: false, valueSelected: ONECLICK })
        this.selectdCard('', 'addCard')
        break
      case 'enrolamiento':
        this.setState({ selectCard: '', btnDisabled: false })
        break
      case 'etPay':
        this.setState({ selectCard: '', btnDisabled: false, valueSelected: ETPAY })
        this.selectdCard('', ETPAY)
        break

      default:
        const valueArray = event.split('-')

        if (valueArray.length < 2) {
          break
        }
        const valType = parseInt(valueArray[0])
        const val = valueArray[1]

        if (valType === 1) {
          this.setState({ selectCard: '', btnDisabled: false, valueSelected: BECH, selectAccount: val })
          this.selectdCard(val, BECH)
        } else if (valType === 2) {
          this.setState({ selectCard: val, btnDisabled: false, valueSelected: ONECLICK })
          this.selectdCard(val, ONECLICK)
        }
        break
    }
  }

  selectdCard = (idCard: string, method: string) => {
    const { oneClick, bancoEstado, paymentMethods } = this.state
    let promotion: any = {}
    if (method === 'bancoEstado') {
      const selectBancoEstado = bancoEstado.find((card: any) => card._id === idCard)
      const card = `${selectBancoEstado.data.descStatusEnrolamiento.tipoCuenta} **** **** **** ${selectBancoEstado.data.descStatusEnrolamiento.idCuenta}`
      promotion = paymentMethods.find((promotion: any) => promotion.promotion && promotion.name === 'Banco Estado')

      this.setState({ paymentName: card, totalDiscountPromotion: promotion && promotion.promotion.discountAmount })
      this.addPromotionToCart(promotion)
    }

    if (method === 'oneClick') {
      const selectOneClick = oneClick.find((card: any) => card._id === idCard)
      const card = `${selectOneClick.creditCardType.replaceAll(
        '-',
        '',
      )} **** **** **** ${selectOneClick.last4CardDigits.replaceAll('X', '')}`
      promotion = paymentMethods.find((promotion: any) => promotion.promotion && promotion.name === 'OneClick')

      this.setState({ paymentName: card, totalDiscountPromotion: promotion && promotion.promotion.discountAmount })
      this.addPromotionToCart(promotion)
    }

    if (method === 'webPay') {
      promotion = paymentMethods.find((promotion: any) => promotion.promotion && promotion.name === 'Webpay')
      this.setState({ totalDiscountPromotion: promotion && promotion.promotion.discountAmount })
      this.addPromotionToCart(promotion)
    }

    if (method === 'etPay') {
      promotion = paymentMethods.find((promotion: any) => promotion.promotion && promotion.name === 'EtPay')
      this.setState({ totalDiscountPromotion: promotion && promotion.promotion.discountAmount })
      this.addPromotionToCart(promotion)
    }

    if (promotion && (Object.keys(promotion).length === 0 || method === 'addCard' || method === 'enrollBech')) {
      this.removePromotionToCart()
      this.setState({ promotion: '' })
    }
  }

  addPromotionToCart = (promotion: any) => {
    let cart: any = this.localStorageCart()
    this.setState({ promotion: '' })

    if (promotion && promotion.promotion) {
      const repeatPromotion = cart.discounts.find((item: any) => item.paymenMethodId === promotion._id)

      if (!repeatPromotion) {
        const promo = {
          amount: promotion.promotion.discountAmount.toString(),
          discountType: 'pay-promo',
          paymentMethod: promotion.promotion.payMethodName,
          paymenMethodId: promotion._id,
          realDiscount: promotion.promotion.discountAmount.toString(),
          idPromotion: promotion.promotion._id,
        }
        let discounts = cart.discounts
        discounts = discounts.filter((discountType: any) => discountType.discountType !== 'pay-promo')
        cart.discounts = [...discounts, promo]
        const cartUdape = cart

        localStorage.setItem('@cart', JSON.stringify(cartUdape))
      }
      this.setState({ promotion: promotion.promotion })
    } else {
      this.removePromotionToCart()
    }
  }

  removePromotionToCart = () => {
    let cart: any = this.localStorageCart()
    cart.discounts = cart.discounts.filter((cart: any) => cart.discountType !== 'pay-promo')
    localStorage.setItem('@cart', JSON.stringify(cart))
    this.setState({ totalDiscountPromotion: 0 })
  }

  localStorageCart = () => {
    const cartLocalStorage = localStorage.getItem('@cart')
    const cart = cartLocalStorage ? JSON.parse(cartLocalStorage) : null
    return cart
  }

  validateIfExistsPaymentMethod = (getEnrollBech: any, getEnrollOneClick: any) => {
    const { paymentName } = this.state

    if (getEnrollBech && getEnrollOneClick) {
      return (
        <div className="select-payment-method" onClick={this.choosePaymentMethod}>
          <h5>{paymentName}</h5>
          <div className="icon-arrow">
            <IonIcon src={Arrow}  />
          </div>
        </div>
      )
    }
    return (
      <div className="loading-offers">
        <p className="loading">Cargando métodos de pago</p>
        <IonSpinner className="spinner-mid" name="crescent"  />
      </div>
    )
  }

  namePayTitle = () => {
    try {
      const countryName = getLocalStorageObject('countryName') || null
      if (!countryName) return ''

      const titleByCountry: any = {
        [countryCl]: 'Pago contra-entrega',
        [countryPe]: '',
        [countryMx]: '',
      }

      return titleByCountry[countryName?.countryCode] || ''
    } catch (err) {
      console.error(err)
    }
  }

  toggleShippingGuru = () => this.state.showModadalPaymentMethod && this.state.paymentMethods.length > 0

  ifShowShippingGuru = () => {
    const { showShippingGuru, arrProviderSelectedDate } = this.state
    this.setState({
      showShippingGuru: !showShippingGuru,
    })

    if (!showShippingGuru) {
      const countryCode = getCountryCode()
      const cartId = this.cartId()

      this.state.providerShippingGuru.forEach((item: any) => {
        const provider = this.props.providerModel.getById(item.providerId)
        const dispatchData = {
          cartId,
          vendorId: provider.id,
          vendorName: provider.name,
          countryCode,
        }
        if (arrProviderSelectedDate.length) {
          dispatchModifyDayClick({ ...dispatchData })
        } else {
          dispatchProgramClick({ ...dispatchData })
        }
      })
    }
  }

  getArrGuruShipping = (arrProviderSelected: any) => {
    const arrProviderSelectedDate = [...this.state.arrProviderSelectedDate]

    this.setState({
      arrProviderSelectedDate: arrProviderSelected,
      nameContainerShowShippingGuru: arrProviderSelected.length ? 'Modificar fecha' : 'Elige el día',
    })

    this.saveEventsDispatchDateProvider(arrProviderSelected, arrProviderSelectedDate)
  }

  saveEventsDispatchDateProvider = (arrProviderSelected: any[], arrProviderSelectedDate: any[]) => {
    try {
      if (arrProviderSelected.length) {
        const cartId = this.cartId()
        arrProviderSelected.forEach((item: any) => {
          const provider = this.props.providerModel.getById(item._id)
          if (provider) {
            const dispatchData = {
              cartId,
              countryCode: getCountryCode(),
              vendorId: item._id,
              vendorName: provider.name,
              selectedDate: item.date,
            }
            if (arrProviderSelectedDate.length) {
              const checkIfNotExist: any = arrProviderSelectedDate.find((selectedDate) => {
                return item.date !== selectedDate.date && item._id === selectedDate._id
              })
              if (checkIfNotExist) {
                const confirmModidyDispatchDate = { ...dispatchData, previusDate: checkIfNotExist.date }

                dispatchConfirmModifyDayClick({ ...confirmModidyDispatchDate })
              }
            } else {
              dispatchConfirmDayClick({ ...dispatchData })
            }
          }
        })
      }
    } catch (error) {
      console.error(error)
    }
  }

  handleSetSelectedSalesman = (event) => {
    let aux  = [...this.state.selectedSalesmen]
    let index = aux.findIndex(elem => elem.providerId === event.target.value.providerId)

    if(index === -1) {
      aux.push({
        providerId: event.target.value.sellerId,
        salesmanId: event.target.value._id
      })
    }
    else {
      aux[index] = {
        providerId: event.target.value.sellerId,
        salesmanId: event.target.value._id
      }
    }

    this.setState({ selectedSalesmen: aux })

  }

  isSalesmenSelected = (productsGroupedMethod) => {
    console.log(this.state.selectedSalesmen.length)
    console.log("->",Object.keys(productsGroupedMethod).map((providersId: any) => providersId).length)
    return this.state.selectedSalesmen.length === Object.keys(productsGroupedMethod).map((providersId: any) => providersId).length
  }

  selectSalesman = (provider, selectedSalesmen) => {
    const findSeletedSalesman = (provider: any) => {
      let aux = selectedSalesmen.find(item => item.providerId === provider.providerId)
      return provider.salesmen?.find(item => item._id === aux?.salesmanId)
    }
    const providerInfo = this.props.providerModel.getById(provider.providerId)
    console.log("asdasdasd",providerInfo)
    return (
      <div className="user-contact-info-wrapper">
        <div className="text-title">
          <h5>{providerInfo.alias}</h5>
        </div>
        <IonSelect
          className="custom-select"
          placeholder={'Seleccionar vendedor'}
          interface="action-sheet"
          cancelText="Cancelar"
          onIonChange={this.handleSetSelectedSalesman}
          value={findSeletedSalesman(provider)}
          key={provider.providerId}
        >
          {provider && provider.salesmen.length > 0 &&
            provider.salesmen.map((item:any) =>(
              <IonSelectOption
                key={item._id}
                value={item}
              >
                  {item.name}
              </IonSelectOption>)
            )
          }
      </IonSelect>
    </div>
    )
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.providerSalesmen !== this.state.providerSalesmen && this.state.providerSalesmen.length > 0) {
      this.state.providerSalesmen.map((elem) => {
        console.log("===>>",elem)
        if(elem.salesmen.length === 0){
          let aux  = [...this.state.selectedSalesmen]
          aux.push({
            providerId: elem.providerId,
            salesmanId: "6789232aa6f471541514529d"
          })
          this.setState({ selectedSalesmen: aux })
        }
        else if(elem.salesmen.length === 1){
          let aux  = [...this.state.selectedSalesmen]
          aux.push({
            providerId: elem.providerId,
            salesmanId: elem.salesmen[1]._id
          })
          this.setState({ selectedSalesmen: aux })
        }
      })
    }
  }

  render() {
    const {
      cartModel,
      singleProviderId,
      productsGroupedMethod,
      isOpenModal,
      providerModel,
      metodoPagoUser,
      paymentMethod,
      totalCart,
      totalShippingCost,
    } = this.props

    const {
      offices,
      showToast,
      success,
      form,
      userInfo,
      guruProModal,
      couponsActive,
      oneClick,
      bancoEstado,
      paymentMethods,
      valueSelected,
      onSending,
      statusOrder,
      isWeb,
      showModadalPaymentMethod,
      totalDiscountPromotion,
      promotion,
      getEnrollBech,
      getEnrollOneClick,
      showModalExceedPayment,
      showShippingGuru,
      nameContainerShowShippingGuru,
      arrProviderSelectedDate,
      providerShippingGuru,
    } = this.state
    const productsGrouped = productsGroupedMethod

    const discountsGrouped = groupBy(cartModel.getDiscounts(), (p: any) => p?.providerId)
    const subTotals: SubTotals = {}
    let show: boolean = false
    const providerIdsToKeep = new Set<string>()
    const discountVerified = this.verifyDiscount()
    let providersKeep: Provider[] = []
    for (const providerId in productsGrouped) {
      subTotals[providerId] = {
        subTotal: this.getTotalPrice(productsGrouped[providerId]),
        minimum: providerModel.getById(providerId).minimun,
        paymentMethods: providerModel.getById(providerId).paymentMethods,
        isIncluded: !singleProviderId || singleProviderId === providerId,
      }
    }
    var subTotals2: any = []
    for (const provider in discountsGrouped) {
      subTotals2[provider] = {
        subTotal: discountsGrouped[provider][0].realDiscount,
        isIncluded: !singleProviderId || singleProviderId === provider,
      }
    }
    var sumDiscounts = 0
    for (const codeProvider in subTotals2) {
      if (subTotals2[codeProvider]['isIncluded']) {
        sumDiscounts = sumDiscounts + parseInt(subTotals2[codeProvider]['subTotal'])
      }
    }

    let officeUser: any = ''
    for (let i = 0; i < offices.length; i++) {
      officeUser = `${offices[i].location.address} - ${offices[i].location.city}`
    }

    const total = Object.keys(subTotals).reduce<number>((sum, curr) => {
      const { isIncluded, subTotal, minimum, paymentMethods } = subTotals[curr]

      const isPaymentForm =
        paymentMethods &&
        paymentMethods.find((r: any) => {
          return r.name === 'Webpay' || r.name === 'OneClick' || r.name === 'Banco Estado'
        })
          ? true
          : false
      if (isIncluded && (!minimum || minimum <= subTotal) && (!isPaymentForm || isPaymentForm === true)) {
        return subTotal + sum
      } else if (isIncluded) {
        providerIdsToKeep.add(curr)
      }
      return sum
    }, 0)
    if (providerIdsToKeep.size > 0) {
      show = true
      providersKeep = Array.from(providerIdsToKeep).map((providerId) => providerModel.getById(providerId))
    }

    let isPayment: any = []
    let isNotPayment: any = []
    let isCredit: any = []

    for (const type of metodoPagoUser) {
      if (type.type === 'pre-pago') {
        isPayment.push(type.providerId)
      } else if (type.type === 'post-pago') {
        isNotPayment.push(type.providerId)
      } else if (type.type === 'credito') {
        isCredit.push(type.providerId)
      }
    }

    const isPrepay = paymentMethod === 'pre-pago'
    const paymentTypeInfo = isPrepay
      ? {
          resume: 'Pago anticipado',
          paymentButton: this.getButtonPayName(),
          info: '',
        }
      : {
          resume: this.namePayTitle(),
          paymentButton: `Pedir Total ${formatCurrency(totalCart + totalShippingCost)}`,
          info: '',
        }

    const isPrepayMethodInvalid = isPrepay ? valueSelected === '' : false

    const { length } = cartModel.getCart()

    const current = localStorage.getItem('currentOffice')
    const currentOffice = current ? JSON.parse(current) : 0

    const handleOnFocusInputElement = (inputName: string) => {
      registerBasicUserInfoEvent({
        eventName: `cart_confirm_order_page_${inputName}_input_click`,
        placementName: 'cart_confirm_order'
      })
    }

    return (
      <IonModal isOpen={isOpenModal} className="order-confirmation-page ds-content-page" backdropDismiss={false}>
        <IonAlert
          isOpen={show}
          header={'¡Espera!'}
          className={'alert-class'}
          message={this.getAlertText(providersKeep)}
          buttons={[
            {
              text: 'No',
              role: 'cancel',
              cssClass: 'secondary',
              handler: this.hideAlert,
            },
            {
              text: 'Sí',
              handler: (): void => console.log('Okay'),
            },
          ]}
        />
        {((userInfo && (!statusOrder.paid || userInfo.porcentajeDeAvance >= 100)) ||
          (statusOrder.paid && userInfo.porcentajeDeAvance === 100)) &&
          statusOrder.subHeader !== 'Realizado' && (
            <AlertModal
              label={statusOrder.header}
              text={statusOrder.subHeader}
              buttonText={statusOrder.nameBtn}
              isOpen={statusOrder.open}
              buttonAction={() => this.handleFeedbackAlertEvent()}
              onDismiss={() => this.handleFeedbackAlertEvent()}
            />
          )}
        <IonToast
          isOpen={showToast}
          onDidDismiss={() => this.setShowToast(false)}
          message="¡Orden enviada!"
          duration={2000}
        />
        {!success && (
          <ToolBar
            title="Confirmar pedido"
            secondaryButtons={[{ type: 'back', onClick: this.goBack }]}
            secondaryHeader={true}
            textCenter={true}
            boxShadow={true}
          />
        )}
        {success && userInfo && (
          <React.Fragment>
            {guruProModal && userInfo.porcentajeDeAvance < 100 && displayByCountry() && <GuruPro orderConfirmation />}
            {!guruProModal && <OrderConfirmedModal onClick={this.onCloseSuccessModal} />}
          </React.Fragment>
        )}
        {!success && (
          <IonContent >
            <div className="user-information">
              <div className="container-info-user-order">
                <div className="address-section">
                  <div className="office-desc-text">
                    <img src={locationIcon} alt="location icon" width={13} height={16} />
                    <h5>Recibe en:</h5>
                  </div>
                  <div className="address-select paragraph-2">
                    {`${currentOffice.location.address} - ${currentOffice.location.city}`}
                  </div>
                </div>
              </div>

              <div className="user-contact-info-wrapper">
                <div className="dynamic-input-wrapper">
                  <TextInput
                    onChange={(event: any) => this.onChangeInput('phone', event.target.value || '')}
                    placeholder={phonePlaceholderByCountry()}
                    label="Teléfono"
                    error={'Escribe tu número con el código de área'}
                    value={form.phone}
                    type="tel"
                    isValid={isPhoneValidByCountry(form.phone) && Boolean(form.phone)}
                    toValidate={form.phone}
                    maxLength={phoneLengthByCountry()}
                    autoComplete="off"
                    iconDefault="icn_phone"
                    iconValid="icn_phone-blue"
                    iconInvalid="icn_phone-red"
                    isEmptyValidStyle={false}
                    onFocus={() => handleOnFocusInputElement("phone")}
                  />
                </div>
                <div className="dynamic-input-wrapper">
                  <TextInput
                    iconDefault="icn_email"
                    iconValid="icn_email-blue"
                    iconInvalid="icn_email-red"
                    onChange={(e: any) => this.onChangeInput('email', e.target.value || '')}
                    placeholder="Ej: maria@email.com"
                    label="Email"
                    isValid={!!validateEmail(form.email) && Boolean(form.email)}
                    error={'Tu email debe ser válido para recibir mensajes'}
                    value={form.email}
                    type="email"
                    toValidate={form.email}
                    autoComplete="off"
                    isEmptyValidStyle={false}
                    onFocus={() => handleOnFocusInputElement("email")}
                  />
                </div>
              </div>
              {this.state.providerSalesmen && this.state.providerSalesmen.length > 0 && this.state.providerSalesmen.map(provider =>

              provider.salesmen.length > 0 &&
                this.selectSalesman(provider, this.state.selectedSalesmen)
              )
                }
              <div className="comment-section">
                <div className="tittle-phone-section">Agregar comentario</div>
                <IonTextarea
                  value={form.comment}
                  onFocus={() => handleOnFocusInputElement("comment")}
                  placeholder={
                    this.state.placeholderFulfillment
                      ? 'Ej: punto de referencia de tu negocio'
                      : commentPlaceholderByCountry()
                  }
                  onIonChange={(e: any) => this.onChangeInput('comment', e.detail.value || '')}
                  clearOnEdit={true}                 ></IonTextarea>
              </div>
            </div>
            {/* {providerShippingGuru.map((item:any)=>{
                return(
                  item?.nextDays.length > 0 &&
                  <div className="containerShipping">
                    <div className="containerShipping__title" >{'Despacho Gurú'}</div>
                    <div 
                    className="containerShipping__element"
                    onClick={() => this.ifShowShippingGuru()}
                    >
                        <div  className="containerShipping__element--modifier">{nameContainerShowShippingGuru}</div>
                        <div className="icon-arrow"><IonIcon src={Arrow} /></div>
                    </div>
                  </div>
                  )
              }) } */}
            {this.shippingGuruIsEnabled() > 0 && (
              <div className="containerShipping">
                <div className="containerShipping__title">{'Despacho Gurú'}</div>
                <div className="containerShipping__element" onClick={() => this.ifShowShippingGuru()}>
                  <div className="containerShipping__element--modifier">{nameContainerShowShippingGuru}</div>
                  <div className="icon-arrow">
                    <IonIcon src={Arrow}  />
                  </div>
                </div>
              </div>
            )}
            <div className="resume-section">
              <div className="provider-list-div">
                <div className="text-title">
                  <h5>Resumen</h5>
                </div>
                <div className="previous-payment paragraph-2">{paymentTypeInfo.resume}</div>
                {Object.keys(productsGrouped).map((providerId: any) => {
                  const provider = providerModel.getById(providerId)
                  const subtotal = productsGrouped[providerId]
                    .map((r: any) => {
                      return r.infoPrice
                    })
                    .reduce((suma: any, { totalPrice }: any) => suma + totalPrice, 0)
                  const isMinimumFulfilled =
                    (!provider.minimun || subtotal >= provider.minimun) &&
                    (!singleProviderId || (singleProviderId && singleProviderId === providerId))
                  const difference = provider.minimun ? provider.minimun - subtotal : 0
                  const providerSelectedGuru =
                    arrProviderSelectedDate.find((item: any) => item._id === provider.id) || ''

                  if (
                    isMinimumFulfilled &&
                    (!singleProviderId || (singleProviderId && singleProviderId === providerId))
                  ) {
                    const total = subtotal - this.discountVerifyByProvider(providerId, couponsActive)
                    const shippingCost = this.getShippingCostProvider(providerId)
                    return (
                      <div key={providerId}>
                        <Fragment key={providerId}>
                          <div className="container-title-provider">
                            <div className={isMinimumFulfilled ? 'provider-summary' : 'provider-summary invalid'}>
                              <div className="provider-header paragraph-2">
                                <div className="provider-title paragraph-2">
                                  <div className="name-provider paragraph-2">{provider.alias}</div>
                                  <div className="provider-products">
                                    {this.discountVerifyByProvider(providerId, couponsActive) > 0 ? (
                                      <IonText className="provider-total-value paragraph-2" >
                                        {formatCurrency(total)}
                                      </IonText>
                                    ) : (
                                      <IonText className="provider-total-value paragraph-2" >
                                        {formatCurrency(subtotal)}
                                      </IonText>
                                    )}
                                  </div>
                                </div>
                                {shippingCost !== null && (
                                  <div className="provider-title paragraph-2">
                                    <div className="delivery-info delivery-info">Costo de despacho</div>
                                    {shippingCost > 0.1 ? (
                                      <div className="delivery-info delivery-info">{formatCurrency(shippingCost)}</div>
                                    ) : (
                                      <div className="delivery-info-free">¡Gratis!</div>
                                    )}
                                  </div>
                                )}
                                <div>
                                  {!provider.alias.toLowerCase().includes('coliseo') ? (
                                    <div className="delivery-info delivery-info">
                                      Entrega{' '}
                                      {providerSelectedGuru != ''
                                        ? `${providerSelectedGuru.day.slice(0, 3)} ${moment(
                                            providerSelectedGuru.date,
                                          ).format('DD/MM/YYYY')}`
                                        : `${provider.deliveryInfo.days || 3 - 5} días`}
                                    </div>
                                  ) : (
                                    <div className="delivery-info paragraph-2">
                                      {moment().hour() >= 16
                                        ? `${moment().add(2, 'days').format('ddd DD/MM/YYYY')}`
                                        : `${moment().add(1, 'days').format('ddd DD/MM/YYYY')}`}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="separator" />
                              <div className="provider-footer">
                                {provider.deliveryDays &&
                                  provider.deliveryDays.length > 0 &&
                                  provider.deliveryDays[0] != null && (
                                    <Fragment>
                                      <div>
                                        <IonText className="provider-delivery" >
                                          Días de entrega:{' '}
                                          {provider.deliveryDays.map((i) => weekDay(i, false)).join(', ')}
                                        </IonText>
                                      </div>

                                      {provider.alias === 'Agrosuper' && (
                                        <div>
                                          <IonText className="provider-delivery" >
                                            {(() => {
                                              const deliveryDate = getDeliveryDate(
                                                new Date(),
                                                new Set(provider.deliveryDays),
                                                16,
                                                2,
                                              )
                                              return `Fecha de entrega: Siguiente ${weekDay(
                                                getDayOfMoment(deliveryDate),
                                              )} ${deliveryDate.date()} de ${month(deliveryDate.month())}`
                                            })()}
                                          </IonText>
                                        </div>
                                      )}
                                    </Fragment>
                                  )}
                                {difference > 0 && (
                                  <IonText className="provider-minimum-message" >
                                    Este proveedor solicita pre-pago.
                                  </IonText>
                                )}
                              </div>
                            </div>
                          </div>
                        </Fragment>
                      </div>
                    )
                  } else {
                    return null
                  }
                })}
                <div className="provider-list-div">
                  <div className="text-title">
                    <h5>Descuentos</h5>
                  </div>
                  <div className="container-discount">
                    {couponsActive.length === 0 && promotion === '' && <div className="not-total">--</div>}
                    {couponsActive && couponsActive.length > 0
                      ? Object.keys(productsGrouped).map((providerId: any) => {
                          return couponsActive.map((coupon: any) => {
                            return (
                              coupon.providerId === providerId && (
                                <div className="total-discount">
                                  <div>{coupon.code}</div>
                                  <div>{formatCurrency(coupon.realDiscount)}</div>
                                </div>
                              )
                            )
                          })
                        })
                      : promotion === '' && <div className="total-discount-not">{formatCurrency(0)}</div>}
                    {promotion !== '' && <div className="name-promotion  paragraph-2">{promotion.payMethodName}</div>}
                    {promotion !== '' && (
                      <div className="discount-promotion  paragraph-2">{formatCurrency(promotion.discountAmount)}</div>
                    )}
                  </div>
                </div>

                <div className="container-total">
                  <h5>TOTAL</h5>
                  <div>
                    {discountVerified > 0 ? (
                      <h5>{formatCurrency(total - discountVerified - totalDiscountPromotion + totalShippingCost)}</h5>
                    ) : (
                      <h5>{formatCurrency(total - totalDiscountPromotion + totalShippingCost)}</h5>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="note">{paymentTypeInfo.info}</div>
            {isPrepay && (
              <div className="payment-method">
                <h5 className="form-method">Método de pago</h5>
                {this.validateIfExistsPaymentMethod(getEnrollBech, getEnrollOneClick)}
              </div>
            )}
          </IonContent>
        )}

        {!success && (
          <div className="confirmation-section">
            <ButtonComponent
              className={`btn-primary`}
              disabled={
                !this.isSalesmenSelected(productsGroupedMethod) ||
                !isPhoneValidByCountry(form.phone) ||
                !validateEmail(form.email) ||
                onSending ||
                isPrepayMethodInvalid ||
                (this.shippingGuruIsEnabled() > 0 && this.shippingGuruIsEnabled() != arrProviderSelectedDate.length)
              }
              onClick={() => this.continueSale(isPayment, isNotPayment, isCredit, discountVerified)}
              text={paymentTypeInfo.paymentButton}
            />
          </div>
        )}
        {showModalExceedPayment && (
          <AlertModal
            label="Ha excedido el pago."
            text="Los abonos del pedido se dividirán según el límite diario definido por BancoEstado hasta completar el pago."
            buttonText="Entiendo y acepto"
            buttonAction={() => this.setState({ showModalExceedPayment: false })}
            isOpen={showModalExceedPayment}
            onDismiss={() => this.setState({ showModalExceedPayment: false })}
          />
        )}
        {this.toggleShippingGuru() && (
          <PaymentModal
            showModadalPaymentMethod={showModadalPaymentMethod}
            closePaymentModal={this.closePaymentModal}
            showPaymentType={paymentMethods}
            selectedPaymentOrderConfirmation={(paymentName: any) => this.selectedPayment(paymentName)}
            cardsOneClick={oneClick}
            cardsBancoEstado={bancoEstado}
            isWebModal={isWeb}
            selectPayment={this.state.paymentSelected}
            {...this.props}
          />
        )}
        {showShippingGuru && (
          <ShippingGuru
            showGuruShipping={showShippingGuru}
            closeGuruShipping={this.ifShowShippingGuru}
            arrProvidersShipping={providerShippingGuru}
            getArrShippingGuru={this.getArrGuruShipping}
            arSelectedProviderDate={arrProviderSelectedDate}
            providersWithShippingGuru={this.shippingGuruIsEnabled()}
          />
        )}
      </IonModal>
    )
  }
}

export default track({
  page: 'Confirmación Orden',
})(withRouter(OrderConfirmation))
